import React from 'react';
import Layout from '../components/Layout/Layout';
import CoachesTestimonials from '../components/CoachesTestimonials/CoachesTestimonials';
import { GatsbyImage } from 'gatsby-plugin-image';
import {StaticQuery, graphql} from 'gatsby';
import '../styles/main.scss';
import video from '../assets/video.mp4';
import video2 from '../assets/video2.mp4';

const PhilosophyPage = () => {
  return(
    <StaticQuery query={graphql`
      {
        allWpContent(filter: { title: {eq: "Philosophy Page"}}) {
          edges{
            node{
              philosophyPage{
                quote
                philosophy
                banner{
                  gatsbyImage(width: 2000, placeholder: BLURRED)
                }
                bioImage{
                  gatsbyImage(width: 700, height: 500, placeholder: BLURRED)
                }
                bio
              }
            }
          }
        }
      }
      `} render={props => (
        <Layout>
          {
            props.allWpContent.edges.map(content => (
            <>
              <div className='philosophy-heading'>
                <h2 className='heading-1'>EST Football</h2>
              </div>

              <GatsbyImage image={content.node.philosophyPage.banner.gatsbyImage} alt="" />
              <div className={'tech-quote'}>
                Technique <span>over everything.</span>
              </div>
              <div className='video-wrapper'>
                <video src={video} type="video/mp4" className='video' autoPlay muted loop/>
                <div className='video-text-wrapper'>
                  <h2 className='video-heading'>Arvine Esteva</h2>
                  <div dangerouslySetInnerHTML={{__html: content.node.philosophyPage.bio}} className='video-text'/>
                </div>
              </div>
              <CoachesTestimonials />

              <div className='video-wrapper2'>
                <div>Training Session</div>
                <video src={video2} type="video/mp4" className='video' autoPlay muted loop/>
              </div>
            </>
          ))}
        </Layout>
      )} />

  )
}

export default PhilosophyPage;
