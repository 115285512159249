import React from 'react';
import * as style  from './CoachesTestimonials.module.scss';
import {StaticQuery, graphql} from 'gatsby';

const CoachesTestimonials = () => {
	 return(
		 <StaticQuery query={graphql`
       {
         allWpCoach{
           edges{
             node{
               coach{
								 name
								 resume{
									 title1
								 }
								 text
							 }
             }
           }
         }
       }
       `} render={ props => (
         <div className={style.wrapper}>
				 	<h2 className={style.heading}>What Coaches Say...</h2>
           {
             props.allWpCoach.edges.map(coach => (
            	<div className={style.coachTestimonial}>
									<h3>{coach.node.coach.name}</h3>
									<ul className={style.resumeList}>
										{
											coach.node.coach.resume.title1 &&
												<li>{coach.node.coach.resume.title1}</li>
										}
									</ul>
									<p className={style.text}>{coach.node.coach.text}</p>
							</div>
           ))}
         </div>
       )} />
	 )
}
export default CoachesTestimonials;
